import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Loader from 'components/general/Loader'
import Avatar from '@material-ui/core/Avatar'
import CallPreferenceForm from 'components/customer/CallPreferenceForm'
import ScheduleCallForm from 'components/customer/ScheduleCallForm'
import { getSiteClass, getCompanyName } from 'config/helpers/Helpers'

import DetailsContext from 'config/context/DetailsContext'

import componentStyles from 'assets/theme/components/cards/cards/card-button.js'
import componentStylesBadge from 'assets/theme/components/badge.js'

import lorraine from 'resources/images/lorraine-connolly.jpg'
import jacinta from 'resources/images/jacinta-carlile.jpg'
import placeholder from 'resources/images/person-placeholder.png'

const useStyles = makeStyles(componentStyles)
const useStylesBadge = makeStyles(componentStylesBadge)

function ApplicationSubmittedCard(props) {
    const classes = { ...useStyles(), ...useStylesBadge() }
    const companyDetails = useContext(DetailsContext)
    const companyId = companyDetails.companyId
    const loading = props.loading

    function getPersonImage() {
        let person = null
        if (companyId === 'bq') {
            person = placeholder
        }
        if (companyId === 'qf') {
            person = lorraine
        }
        if (companyId === 'nph') {
            person = lorraine
        }
        if (companyId === 'al') {
            person = jacinta
        }
        return person
    }

    function getPersonName() {
        let person = null
        if (companyId === 'bq') {
            person = 'John Smith'
        }
        if (companyId === 'qf') {
            person = 'Lorraine Connolly'
        }
        if (companyId === 'nph') {
            person = 'Lorraine Connolly'
        }
        if (companyId === 'al') {
            person = 'Jacinta Carlile'
        }
        return person
    }
    
    return (
        <>
            <Card
                classes={{
                    root: classes.cardRoot,
                }}
            >
                <CardHeader
                    subheader={
                        <Grid
                            container
                            component={Box}
                            alignItems='center'
                            justifyContent='space-between'
                        >
                            <Grid item xs='auto'>
                                <Box
                                    component={Typography}
                                    variant='h2'
                                    marginBottom='0!important'
                                >
                                    Application Under Review
                                </Box>
                                <Box
                                    component='p'
                                    fontSize='.875rem'
                                    marginBottom='0'
                                    marginTop='0.5rem'
                                    lineHeight='1.7'
                                    fontWeight='300'
                                >
                                    Your information has been submitted and is now processing
                                </Box>
                            </Grid>
                        </Grid>
                    }
                    classes={{ root: classes.cardHeaderRoot }}
                >
                </CardHeader>
                
                <CardContent>
                    {!loading ? (
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container direction='column' justifyContent='center' alignItems='center'>
                                    <Grid item xs={10} style={{marginBottom: '1rem'}}>
                                        <Avatar alt='Pension Advisor' src={getPersonImage()} style={{width: '150px', height: '150px'}} />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography variant='h2'>{getPersonName()}</Typography>
                                    </Grid>
                                    <Grid item xs={10} style={{marginBottom: '1rem'}}>
                                        <Typography variant='h3'>Senior Pension Advisor</Typography>
                                    </Grid>
                                    <Grid item xs={10} style={{marginBottom: '1rem', textAlign: 'center'}}>  
                                        <Typography variant='p' className='normal-p'>Your dedicated pension advisor is {getPersonName()} from {getCompanyName(companyId)}. They will be in touch shortly to discuss your application and provide further advice.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent='center' alignItems='center'>
                                    <Grid item xs={10} style={{marginBottom: '1rem', textAlign: 'center'}}>
                                        <Typography variant='h3'>Schedule a Call</Typography>
                                    </Grid>
                                    <Grid item xs={10}>
                                        {!props.application.callPreference ? (
                                            <ScheduleCallForm 
                                                callTime={props.application.callPreference}
                                                updateApplication={(application) => props.updateApplication(application)}
                                            />
                                        ) : (
                                            <CallPreferenceForm 
                                                note={props.application.callPreference}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container justifyContent='flex-start'>
                            <Grid item xs={12}>
                                <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                                    <Loader />
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                </CardContent>
            </Card>

            <Grid container component={Box} marginTop='1rem'>
                <Grid item xs={12} component={Box} textAlign='center'>
                    <a
                        href='#'
                        onClick={(e) => props.unsubmitApplication()}
                        className={getSiteClass(companyId)}
                        style={{opacity: '50%'}}
                    >
                        Back to Application
                    </a>
                </Grid>
            </Grid>
        </>
    )
}

export default ApplicationSubmittedCard