import React, { useState, useContext } from 'react'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Loader from 'components/general/Loader'
import Checkbox from '@material-ui/core/Checkbox'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import BrokerAPI from 'config/api/BrokerAPI'
import { onError } from 'config/lib/errorLib'
import { NotificationManager } from 'react-notifications'
import { providerOptions } from 'config/constants/Constants'

import DetailsContext from 'config/context/DetailsContext'

function ProviderDetails(props) {
    const api = new BrokerAPI()
    const details = props.details
    const companyDetails = useContext(DetailsContext)
    const companyId = companyDetails.companyId

    const [providerName, setProviderName] = useState(details.providerName ? details.providerName : '')
    const [fundName, setFundName] = useState(details.fundName ? details.fundName : '')
    const [irSent, setIrSent] = useState(details.irSent ? details.irSent : false)
    const [sosSent, setSosSent] = useState(details.sosSent ? details.sosSent : false)
    const [loading, setLoading] = useState(false)
    
    async function updateDetails() {
        setLoading(true)
        try {
            const phone = props.applicantPhone
            let details = {
                providerName,
                fundName,
                irSent,
                sosSent
            }
            let result = await api.updateProviderDetails(companyId, phone, details)

            props.updateApplication(result)
            props.closeContent()
            NotificationManager.success('Details saved')
        } catch(e) {
            onError(e)
        }
        setLoading(false)
    }

    function checkRequiredFields() {
        // if (firstName.length > 0 && lastName.length > 0) {
        //     return false
        // }
        // else {
        //     return true
        // }
        return false
    }

    function updateProviderDetails(field, value) {
        let details = {
            providerName,
            fundName,
            irSent,
            sosSent
        }
        details[field] = value
        props.updateProviderDetails(details)
    }
    
    return (
        <Grid container>
            <Grid item xs={12} lg={12} style={{marginBottom: '1rem'}}>
                <Grid container>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>Provider Name</FormLabel>
                            <FormControl variant='outlined' fullWidth>
                                <Select
                                    multiple={false}
                                    IconComponent={KeyboardArrowDown}
                                    value={providerName}
                                    onChange={(e) => { setProviderName(e.target.value); updateProviderDetails('providerName', e.target.value); }}
                                >
                                    {providerOptions.map((option, i) => {
                                        return (<MenuItem value={option.value} key={i}>{option.label}</MenuItem>)
                                    })}
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>Fund Name</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <OutlinedInput
                                    fullWidth
                                    type='text'
                                    value={fundName}
                                    onChange={(e) => { setFundName(e.target.value); updateProviderDetails('fundName', e.target.value); }}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container direction='row' alignItems='center' justifyContent='flex-start'>
                            <Box>
                                <Checkbox
                                    color='primary' 
                                    checked={irSent} 
                                    onChange={() => { setIrSent(!irSent); updateProviderDetails('irSent', !irSent); }}
                                />
                            </Box>
                            <Box>
                                <FormLabel>Investment Report Sent</FormLabel>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container direction='row' alignItems='center' justifyContent='flex-start'>
                            <Box>
                                <Checkbox
                                    color='primary' 
                                    checked={sosSent} 
                                    onChange={() => { setSosSent(!sosSent); updateProviderDetails('sosSent', !sosSent); }}
                                />
                            </Box>
                            <Box>
                                <FormLabel>Statement of Suitability Sent</FormLabel>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} lg={12}>        
                <Grid container justifyContent='space-between'>
                    <Grid item>
                    </Grid>
                    <Grid item>
                        <Button
                            component={Box}
                            color='primary'
                            variant='contained'
                            width={180}
                            disabled={checkRequiredFields() || loading}
                            onClick={() => updateDetails()}
                        >
                            {!loading ? (
                                <>Update</>
                            ) : (
                                <Loader height={12} />
                            )}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ProviderDetails