import React, { useState, useContext } from 'react'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Loader from 'components/general/Loader'
import Checkbox from '@material-ui/core/Checkbox'
import BrokerAPI from 'config/api/BrokerAPI'
import { onError } from 'config/lib/errorLib'
import { NotificationManager } from 'react-notifications'

import DetailsContext from 'config/context/DetailsContext'

function ComplianceDocuments(props) {
    const api = new BrokerAPI()
    const details = props.details
    const companyDetails = useContext(DetailsContext)
    const companyId = companyDetails.companyId

    const [tobSent, setTobSent] = useState(details.tobSent ? details.tobSent : false)
    const [ffComplete, setFfComplete] = useState(details.ffComplete ? details.ffComplete : false)
    const [loading, setLoading] = useState(false)
    
    async function updateDetails() {
        setLoading(true)
        try {
            const phone = props.applicantPhone
            let details = {
                tobSent,
                ffComplete
            }
            let result = await api.updateComplianceDocuments(companyId, phone, details)

            props.updateApplication(result)
            props.closeContent()
            NotificationManager.success('Details saved')
        } catch(e) {
            onError(e)
        }
        setLoading(false)
    }

    function checkRequiredFields() {
        // if (firstName.length > 0 && lastName.length > 0) {
        //     return false
        // }
        // else {
        //     return true
        // }
        return false
    }

    function updateComplianceDocuments(field, value) {
        let details = {
            tobSent,
            ffComplete
        }
        details[field] = value
        props.updateComplianceDocuments(details)
    }
    
    return (
        <Grid container>
            <Grid item xs={12} lg={12} style={{marginBottom: '1rem'}}>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container direction='row' alignItems='center' justifyContent='flex-start'>
                            <Box>
                                <Checkbox
                                    color='primary' 
                                    checked={tobSent} 
                                    onChange={() => { setTobSent(!tobSent); updateComplianceDocuments('tobSent', !tobSent); }}
                                />
                            </Box>
                            <Box>
                                <FormLabel>Terms of Business Sent</FormLabel>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container direction='row' alignItems='center' justifyContent='flex-start'>
                            <Box>
                                <Checkbox
                                    color='primary' 
                                    checked={ffComplete} 
                                    onChange={() => { setFfComplete(!ffComplete); updateComplianceDocuments('ffComplete', !ffComplete); }}
                                />
                            </Box>
                            <Box>
                                <FormLabel>Fact Find Complete</FormLabel>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} lg={12}>        
                <Grid container justifyContent='space-between'>
                    <Grid item>
                    </Grid>
                    <Grid item>
                        <Button
                            component={Box}
                            color='primary'
                            variant='contained'
                            width={180}
                            disabled={checkRequiredFields() || loading}
                            onClick={() => updateDetails()}
                        >
                            {!loading ? (
                                <>Update</>
                            ) : (
                                <Loader height={12} />
                            )}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ComplianceDocuments