//import 'react-app-polyfill/ie11'
//import 'react-app-polyfill/stable'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { Authenticator } from 'aws-amplify-react'
import Amplify from 'aws-amplify'
import config from './config/api/Config'
import { initSentry } from './config/lib/errorLib'

// maybe move to router
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import nphTheme from 'assets/theme/nphTheme.js'
import pcTheme from 'assets/theme/pcTheme.js'
import qfTheme from 'assets/theme/qfTheme.js'

// plugins styles from node_modules
import 'react-perfect-scrollbar/dist/css/styles.css'
//import '@fullcalendar/common/main.min.css'
//import '@fullcalendar/daygrid/main.min.css'
//import 'quill/dist/quill.core.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
// plugins styles downloaded
import 'assets/plugins/nucleo/css/nucleo.css'
// core styles
import 'assets/scss/argon-dashboard-pro-material-ui.scss?v1.0.0'
// notifications css
import 'react-notifications/lib/notifications.css'
// table css
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
// custom css
import 'css/Custom.css'
//import 'css/Quotes.css'

//const isLocal = process.env.NODE_ENV === 'development'

//const companyId = 'bq'
const companyId = 'qf'

Amplify.configure({
    Auth: {
        mandatorySignIn: false,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID[companyId],
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID[companyId]
        //authenticationFlowType: 'CUSTOM_AUTH'
    },
    API: {
        endpoints: [
            {
                name: config.apiGatewayGuest.PATH,
                endpoint: config.apiGatewayGuest.URL,
                region: config.apiGatewayGuest.REGION
            },
            {
                name: config.apiGatewayCustomer.PATH,
                endpoint: config.apiGatewayCustomer.URL,
                region: config.apiGatewayCustomer.REGION
            },
            {
                name: config.apiGatewayBroker.PATH,
                endpoint: config.apiGatewayBroker.URL,
                region: config.apiGatewayBroker.REGION
            },
            // {
            //     name: config.apiGatewayAdmin.PATH,
            //     endpoint: config.apiGatewayAdmin.URL,
            //     region: config.apiGatewayAdmin.REGION
            // }
        ]
    },
    Storage: {
        region: config.s3.REGION,
        bucket: config.s3.BUCKET[companyId],
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
    }
})

// initialize sentry
initSentry()

function getTheme() {
    let siteTheme = null
    if (companyId === 'bq') {
        siteTheme = null
    }
    if (companyId === 'nph') {
        siteTheme = nphTheme
    }
    if (companyId === 'qf') {
        siteTheme = qfTheme
    }
    if (companyId === 'al') {
        siteTheme = pcTheme
    }
    return siteTheme
}

class AppWithAuth extends Component {
    render() {
        return (
            // Put the App component inside the authenticator component and only render once correct authState is reached
            <Authenticator hideDefault={true}>
                <App />
            </Authenticator>
        );
    }
}

ReactDOM.render(
    <ThemeProvider theme={getTheme()}>
        <CssBaseline />
        <BrowserRouter>
            <AppWithAuth />
        </BrowserRouter>
    </ThemeProvider>, document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);