const isLocal = process.env.NODE_ENV === 'development'

const config =  {
    apiGatewayGuest: {
        REGION: 'eu-west-1',
        URL: isLocal ? 'https://0n7ljuw3h9.execute-api.eu-west-1.amazonaws.com/dev/' : 'https://2fyunnoq94.execute-api.eu-west-1.amazonaws.com/prod/',
        PATH: 'guest-api',
    },
    apiGatewayCustomer: {
        REGION: 'eu-west-1',
        URL: isLocal ? 'https://gbz87x9xyi.execute-api.eu-west-1.amazonaws.com/dev/' : 'https://zmna98z397.execute-api.eu-west-1.amazonaws.com/prod/',
        PATH: 'customer-api',
    },
    apiGatewayBroker: {
        REGION: 'eu-west-1',
        URL: isLocal ? 'https://igpo1f0ggh.execute-api.eu-west-1.amazonaws.com/dev/' : 'https://d6ccf2pccg.execute-api.eu-west-1.amazonaws.com/prod/',
        PATH: 'broker-api',
    },
    cognito: {
        REGION: 'eu-west-1',
        USER_POOL_ID: {
            bq: 'eu-west-1_8xEsykmbL',
            qf: 'eu-west-1_8xEsykmbL',
            //al: 'eu-west-1_cQyKIl2DU',
            nph: 'eu-west-1_8xEsykmbL'
        },
        APP_CLIENT_ID: {
            bq: '7lvn9pqgq2p9m7ip2qbel65ru7',
            qf: '7lvn9pqgq2p9m7ip2qbel65ru7',
            //al: '1k4ubcf15cdldc4jkslm761nlj',
            nph: '7lvn9pqgq2p9m7ip2qbel65ru7'
        },
        IDENTITY_POOL_ID: 'eu-west-1:11b95137-335a-44cb-b5ea-9440e3941bfb'
    },
    s3: {
        REGION: 'eu-west-1',
        BUCKET: {
            bq: 'start-pension-user-uploads',
            qf: 'start-pension-user-uploads',
            //al: 'start-pension-pc-user-uploads',
            nph: 'start-pension-user-uploads'
        }
    },
    SENTRY_DSN: 'https://8fa951ee651fbaa6af9af62f26f60eb9@o290751.ingest.sentry.io/4506179045556224',

}
export default config