import React, { useState, useContext } from 'react'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Loader from 'components/general/Loader'
import SelectProvider from 'components/general/SelectProvider'
import TaxReliefBox from 'components/customer/TaxReliefBox'
import CustomerAPI from 'config/api/CustomerAPI'
import { onError } from 'config/lib/errorLib'
import { NotificationManager } from 'react-notifications'
import { 
    monthOptions,
    employmentStatusOptions
} from 'config/constants/Constants'
import { getPrimaryGoalOptions } from 'config/helpers/Helpers'
import { Auth } from 'aws-amplify'

import DetailsContext from 'config/context/DetailsContext'

function PensionDetails(props) {
    const api = new CustomerAPI()
    const details = props.details
    const companyDetails = useContext(DetailsContext)
    const companyId = companyDetails.companyId
    const isBroker = props.broker

    const [employmentStatus, setEmploymentStatus] = useState(details.employmentStatus ? details.employmentStatus : '')
    const [occupation, setOccupation] = useState(details.occupation || '')
    const [annualEarnings, setAnnualEarnings] = useState(details.annualEarnings || '')
    const [primaryGoal, setPrimaryGoal] = useState(details.primaryGoal || '')
    const [retirementAge, setRetirementAge] = useState(details.retirementAge || '65')
    const [startDate, setStartDate] = useState(details.startDate || 'January')
    const [contribution, setContribution] = useState(details.contribution || '')
    const [frequency, setFrequency] = useState(details.frequency || 'Monthly')
    const [transferType, setTransferType] = useState(details.transferType || 'Bank Transfer')
    //const [accountName, setAccountName] = useState(details.accountName || '')
    //const [iban, setIban] = useState(details.iban || '')
    const [preferredProvider, setPreferredProvider] = useState(details.preferredProvider || 'Not Sure')
    const [loading, setLoading] = useState(false)
    
    async function updateDetails() {
        setLoading(true)
        try {
            const info = await Auth.currentUserInfo()
            const phone = isBroker ? props.applicantPhone : info.attributes['phone_number']
            let details = {
                employmentStatus,
                occupation,
                annualEarnings,
                retirementAge,
                primaryGoal,
                startDate,
                contribution,
                frequency,
                transferType,
                //accountName,
                //iban,
                preferredProvider
            }
            let result = await api.updatePensionDetails(companyId, phone, details)

            props.updateApplication(result)
            if (props.broker) {
                props.closeContent()
            }
            else {
                props.updateStage(2)
            }
            NotificationManager.success('Details saved')
        } catch(e) {
            onError(e)
        }
        setLoading(false)
    }

    function checkRequiredFields() {
        // if (firstName.length > 0 && lastName.length > 0) {
        //     return false
        // }
        // else {
        //     return true
        // }
        return false
    }

    function updatePensionDetails(field, value) {
        let details = {
            employmentStatus,
            occupation,
            annualEarnings,
            retirementAge,
            primaryGoal,
            startDate,
            contribution,
            frequency,
            transferType,
            //accountName,
            //iban,
            preferredProvider
        }
        details[field] = value
        if (isBroker) {
            props.updatePensionDetails(details)
        }
    }
    
    return (
        <Grid container>
            <Grid item xs={12} lg={12} style={{marginBottom: '1rem'}}>
                <Grid container>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>Employment Status</FormLabel>
                            <FormControl variant='outlined' fullWidth>
                                <Select
                                    multiple={false}
                                    IconComponent={KeyboardArrowDown}
                                    value={employmentStatus}
                                    onChange={(e) => { setEmploymentStatus(e.target.value); updatePensionDetails('employmentStatus', e.target.value); }}
                                >
                                    {employmentStatusOptions.map((option, i) => {
                                        return (<MenuItem value={option.value} key={i}>{option.label}</MenuItem>)
                                    })}
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>Occupation</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <OutlinedInput
                                    fullWidth
                                    type='text'
                                    value={occupation}
                                    onChange={(e) => { setOccupation(e.target.value); updatePensionDetails('occupation', e.target.value); }}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>Gross Annual Earnings (including overtime/bonuses)</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <OutlinedInput
                                    fullWidth
                                    type='text'
                                    value={annualEarnings}
                                    onChange={(e) => { setAnnualEarnings(e.target.value); updatePensionDetails('annualEarnings', e.target.value); }}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>Primary Goal in Retirement</FormLabel>
                            <FormControl variant='outlined' fullWidth>
                                <Select
                                    multiple={false}
                                    IconComponent={KeyboardArrowDown}
                                    value={primaryGoal}
                                    onChange={(e) => { setPrimaryGoal(e.target.value); updatePensionDetails('primaryGoal', e.target.value); }}
                                >
                                    {getPrimaryGoalOptions().map((option, i) => {
                                        return (<MenuItem value={option.value} key={i}>{option.label}</MenuItem>)
                                    })}
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>Target Retirement Age</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <OutlinedInput
                                    fullWidth
                                    type='text'
                                    value={retirementAge}
                                    onChange={(e) => { setRetirementAge(e.target.value); updatePensionDetails('retirementAge', e.target.value); }}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>Monthly Contribution (€)</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <OutlinedInput
                                    fullWidth
                                    type='text'
                                    value={contribution}
                                    onChange={(e) => { setContribution(e.target.value); updatePensionDetails('contribution', e.target.value); }}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>Starting Month</FormLabel>
                            <FormControl variant='outlined' fullWidth>
                                <Select
                                    multiple={false}
                                    IconComponent={KeyboardArrowDown}
                                    value={startDate}
                                    onChange={(e) => { setStartDate(e.target.value); updatePensionDetails('startDate', e.target.value); }}
                                >
                                    {monthOptions.map((option, i) => {
                                        return (<MenuItem value={option.value} key={i}>{option.label}</MenuItem>)
                                    })}
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>Frequency</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <OutlinedInput
                                    fullWidth
                                    type='text'
                                    value={frequency}
                                    disabled={true}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>Transfer Type</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <OutlinedInput
                                    fullWidth
                                    type='text'
                                    value={transferType}
                                    disabled={true}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    {/*
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>Account Name</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <OutlinedInput
                                    fullWidth
                                    type='text'
                                    value={accountName}
                                    onChange={(e) => setAccountName(e.target.value)}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    */}
                    {/*
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>IBAN (Optional)</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <OutlinedInput
                                    fullWidth
                                    type='text'
                                    value={iban}
                                    onChange={(e) => { setIban(e.target.value); updatePensionDetails('iban', e.target.value); }}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    */}
                    {!props.broker && 
                        <Grid item xs={12} lg={12} style={{marginBottom: '2rem'}}>
                            <TaxReliefBox
                                dob={props.dob}
                                salary={annualEarnings}
                                monthlyContribution={contribution}
                                companyId={companyId}
                            />
                        </Grid>
                    }
                    <Grid item xs={12} lg={12}>
                        <FormGroup>
                            <FormLabel>Select your preferred pension provider</FormLabel>
                            <SelectProvider 
                                provider={preferredProvider}
                                updateProvider={(provider) => { setPreferredProvider(provider); updatePensionDetails('preferredProvider', provider); }}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} lg={12}>        
                <Grid container justifyContent='space-between'>
                    <Grid item>
                        {!props.broker &&
                            <Button
                                component={Box}
                                color='secondary'
                                variant='contained'
                                size='medium'
                                onClick={() => props.updateStage(0)}
                            >
                                Back
                            </Button>
                        }
                    </Grid>
                    <Grid item>
                        <Button
                            component={Box}
                            color='primary'
                            variant='contained'
                            width={180}
                            disabled={checkRequiredFields() || loading}
                            onClick={() => updateDetails()}
                        >
                            {!loading ? (
                                <>{!props.broker ? (
                                    <>Save & Continue</>
                                ) : (
                                    <>Update</>
                                )}</>
                            ) : (
                                <Loader height={12} />
                            )}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PensionDetails