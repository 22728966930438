import { Component } from 'react'
import { API, Auth } from 'aws-amplify'
import config from './Config'

const apiPath = config.apiGatewayGuest.PATH

class GuestClient extends Component {
    async addApplication(companyId, phone, firstName, lastName, email, dob, primaryGoal, annualEarnings, contribution) {
        //const info = await Auth.currentUserInfo()
		const data = {
			companyId,
			phone,
			firstName,
			lastName,
			email,
			dob,
			primaryGoal,
			annualEarnings,
			contribution
		}
		console.log(data)

		return API.post(apiPath, 'applications/add', {
			body: data
		})
	}
} 

export default GuestClient