import React, { useState, useEffect } from 'react'
import { useLocation, Switch, Route, Redirect } from 'react-router-dom'
import AdminNavbarAlternative from 'components/argon/Navbars/AdminNavbarAlternative.js'
import Box from '@material-ui/core/Box'
import DashboardIcon from '@material-ui/icons/Dashboard'

import { makeStyles } from '@material-ui/core/styles'
import componentStyles from 'assets/theme/layouts/admin.js'

import Applications from 'scenes/broker/Applications'

const useStyles = makeStyles(componentStyles)

const Main = (props) => {
    const classes = useStyles()
    const location = useLocation()
    const [sidebarOpenResponsive, setSidebarOpenResponsive] = useState(false)

    useEffect(() => {
        document.documentElement.scrollTop = 0
        document.scrollingElement.scrollTop = 0
        // mainContent.current.scrollTop = 0
    }, [location])

    var routes = [
        {
            path: '/applications',
            name: 'Applications',
            icon: DashboardIcon,
            iconColor: 'Info',
            component: Applications,
        }
    ]

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return getRoutes(prop.views)
            } 
            else {
                return (
                    <Route
                        path={prop.path}
                        component={prop.component}
                        key={key}
                    />
                )
            }
        });
    };

    return (
        <>
            <Box display='flex'>
                {/*<Sidebar
                    routes={routes}
                    openResponsive={sidebarOpenResponsive}
                    closeSidebarResponsive={() => setSidebarOpenResponsive(false)}
                    accountType='customer'
                    logo={{
                        innerLink: '/',
                        imgSrc: logo,
                        imgAlt: '...',
                        className: 'sidebar-logo',
                    }}
                />*/}
                <Box position='relative' flex='1' className={classes.mainContent}>
                    <AdminNavbarAlternative
                        openSidebarResponsive={() => setSidebarOpenResponsive(true)}
                        accountType='customer'
                        phone={props.phone}
                        name={props.name}
                    />
                    
                    <Switch>
                        {getRoutes(routes)}
                        <Redirect from='*' to='/applications' />
                    </Switch>
                </Box>
            </Box>
        </>
    )
}

export default Main